
export const Footer = () => <div style={{
    color: 'white',
    backgroundColor: 'rgba(.2,.2,.2,.5)',
    bottom: 0,
    position: 'fixed',
    left: 0,
    right: 0,
    textAlign: 'center',
  }}>
  Created by Roberto Miguel ® - IG @robertomiguel.ph
</div>